export const GET_VEHICLE_IMPORTATIONS = "GET_VEHICLE_IMPORTATIONS";
export const GET_VEHICLE_IMPORTATIONS_SUCCESS =
  "GET_VEHICLE_IMPORTATIONS_SUCCESS";
export const GET_VEHICLE_IMPORTATIONS_FAIL = "GET_VEHICLE_IMPORTATIONS_FAIL";
export const ADD_VEHICLE_IMPORTATION = "ADD_VEHICLE_IMPORTATION";
export const ADD_VEHICLE_IMPORTATION_SUCCESS =
  "ADD_VEHICLE_IMPORTATION_SUCCESS";
export const ADD_VEHICLE_IMPORTATION_FAIL = "ADD_VEHICLE_IMPORTATION_FAIL";
export const EDIT_VEHICLE_IMPORTATION = "EDIT_VEHICLE_IMPORTATION";
export const EDIT_VEHICLE_IMPORTATION_SUCCESS =
  "EDIT_VEHICLE_IMPORTATION_SUCCESS";
export const EDIT_VEHICLE_IMPORTATION_FAIL = "EDIT_VEHICLE_IMPORTATION_FAIL";
export const DELETE_VEHICLE_IMPORTATION = "DELETE_VEHICLE_IMPORTATION";
export const DELETE_VEHICLE_IMPORTATION_SUCCESS =
  "DELETE_VEHICLE_IMPORTATION_SUCCESS";
export const DELETE_VEHICLE_IMPORTATION_FAIL =
  "DELETE_VEHICLE_IMPORTATION_FAIL";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
