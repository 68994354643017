export const GET_MARITIME_ADMINISTRATIONS = "GET_MARITIME_ADMINISTRATIONS";
export const GET_MARITIME_ADMINISTRATIONS_SUCCESS =
  "GET_MARITIME_ADMINISTRATIONS_SUCCESS";
export const GET_MARITIME_ADMINISTRATIONS_FAIL = "GET_MARITIME_ADMINISTRATIONS_FAIL";
export const ADD_MARITIME_ADMINISTRATION = "ADD_MARITIME_ADMINISTRATION";
export const ADD_MARITIME_ADMINISTRATION_SUCCESS =
  "ADD_MARITIME_ADMINISTRATION_SUCCESS";
export const ADD_MARITIME_ADMINISTRATION_FAIL =
  "ADD_MARITIME_ADMINISTRATION_FAIL";
export const EDIT_MARITIME_ADMINISTRATION =
  "EDIT_MARITIME_ADMINISTRATION";
export const EDIT_MARITIME_ADMINISTRATION_SUCCESS =
  "EDIT_MARITIME_ADMINISTRATION_SUCCESS";
export const EDIT_MARITIME_ADMINISTRATION_FAIL =
  "EDIT_MARITIME_ADMINISTRATION_FAIL";
export const DELETE_MARITIME_ADMINISTRATION =
  "DELETE_MARITIME_ADMINISTRATION";
export const DELETE_MARITIME_ADMINISTRATION_SUCCESS =
  "DELETE_MARITIME_ADMINISTRATION_SUCCESS";
export const DELETE_MARITIME_ADMINISTRATION_FAIL =
  "DELETE_MARITIME_ADMINISTRATION_FAIL";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
